import React from 'react'
import { useForm } from '@formspree/react';
export default function Contact(e) {
    const [state, handleSubmit] = useForm("moqgwybp");

    if (state.succeeded) {
  
      return <Contact name={"You will be notified soon!"} />
  
    }
  
    return (
        <>   
            <div className="contact">
         
                <div className="gradient"></div>

                <div className="container">
                    <div className="row" data-aos="fade-up" data-aos-duration="400">
                        <div className="col-lg-6 col-md-12 col-12 columns-1">
                            <h2>Our Address</h2>
                            <address>
                                <p  style={{fontSize:"30px"}}>India HQ</p>
                                <p>A-5 , SECTOR-49, </p>
                                <p>NOIDA, Uttar Pradesh 201303</p>
                             
                                <p style={{fontSize:"30px"}}>United States of America</p>
                                <p>220 Rowan Blvd. Apt# 522, Glassboro</p>
                                <p> New Jersey, 08028</p>
                                <p>Opening Hours : Mo-Sa 06:00-19:00</p>
                                <p>Call for Bookings : <a href="tel:+918285038252"><span>8285038252</span></a>,<a href="tel:+18562141555"><span>+1 (856) 214-1555</span></a></p>
                            </address>
                        </div>
                        <div className="col-lg-1 col-md-12 col-12"></div>
                        <div className="col-lg-5 col-md-12 col-12 columns-2">
                            <h2>Quick Contact</h2>
                            <h3>{e.name}</h3>
                            <form className="row form-inline" onSubmit={handleSubmit}>
                                <div className="col-md-6 form-group">
                                    <input type="text" className="form-control" name="name" placeholder="Your Name" required />
                                </div>
                                <div className="col-md-6 form-group">
                                    <input type="email" className="form-control" name="email" placeholder="Your Email" required />
                                </div>
                                <div className="col-md-12 form-group">
                                    <input type="number" className="form-control"  name="ph" placeholder="Your Phone No" required />
                                </div>
                                <div className="col-md-12 form-group">
                                    <textarea className="form-control" name="message" placeholder="Message" rows="5" cols="70"></textarea>
                                </div>
                                <div className="col-md-12">
                                    <button type="submit" className="btn btn-primary">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="copyright">
                    <div className="container">
                        <div className="row border-img">
                            <div className="col-md-12">
                                <img src="images/border.png" alt="" />
                            </div>
                        </div>
                    </div>
                    <div className="container col-md-12 col-12">
                        {/* <div className="row" data-aos="fade-up" data-aos-duration="400">
                            <div className="col-lg-3 col-md-12">
                                <a href="index.html"><img src="img/logo2.png" alt="logo" /></a>
                            </div>
                            <div className="col-lg-9 col-md-12 right-part">
                                <ul className="ml-auto">
                                    <li className="nav-item active">
                                        <a className="nav-link active" href="index.html">Home <span className="sr-only">(current)</span></a>
                                    </li>
                                    <li><a className="hidden-xs">~</a></li>
                                    <li className="nav-item"><a className="nav-link" href="services.html">Our Services</a></li>
                                    <li><a className="hidden-xs">~</a></li>
                                    <li className="nav-item"><a className="nav-link" href="products.html">Our Products</a></li>
                                    <li><a className="hidden-xs">~</a></li>
                                    <li className="nav-item"><a className="nav-link" href="rates.html">Rates</a></li>
                                    <li><a className="hidden-xs">~</a></li>
                                    <li className="nav-item"><a className="nav-link" href="blog-page.html">Read Blog</a></li>
                                </ul>
                            </div>
                        </div> */}<p>All rights reserved @2024</p>
                    </div>
              s
                </div>
            </div>
        </>
    )
}
