import React from 'react'
import Header from '../Header'
export default function TTCcontact() {
    return (
        <>
            <Header />
            <div className="about">

                <div style={{ marginTop: "100px" }} classNameName="container">
                    <div className="row">
                        <div className="col-md-12 col-12 mt-2 heading">
                            <img src="images/leaf.png" alt="" />
                            <h2>Teacher Training Course</h2>
                            <h3>Apply Here</h3>
                        </div>
                    </div>



                    <div className="container-fluid">
                        <div className="row">

                            <div className="contact-form">
                                <form action="" id="contact-form" >

                                    {/* <h1>{e.name}</h1> */}
                                    <h4>Personal Details</h4>

                                    <div className='row'>
                                        <div className="input-box col-xl-6 col-md-6 col-12">
                                            <input type="text" required="true" name="name" placeholder="First Name" id="name" />
                                        </div>
                                        <div className="input-box col-xl-6 col-md-6 col-12">
                                            <input type="text" required="true" name="name" placeholder="Last Name" id="name" />
                                        </div>


                                    </div>

                                    <div className="input-box">
                                        <input type="email" required="true" name="email" placeholder="Email id" id="email" />
                                    </div>
                                    <div className="input-box  ">
                                        <input type="number" required="true" name="ph" placeholder="Phone Number" id="ph" />
                                    </div>

                                    <div class="row">
                                        <div class="col-half col-xl-6">
                                            <h4 style={{fontSize:"14px", fontStyle:"italic", color:"grey"}}>Date of Birth</h4>
                                            <div class="input-group">
                                                <div class="col-third">
                                                    <input type="text" placeholder="DD" />
                                                </div>
                                                <div class="col-third">
                                                    <input type="text" placeholder="MM" />
                                                </div>
                                                <div class="col-third">
                                                    <input type="text" placeholder="YYYY" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-half col-xl-6" style={{marginTop:"5px"}}>
                                            <h4 style={{fontSize:"14px", fontStyle:"italic", color:"grey"}}>Gender</h4>
                                            <div class="input-group">
                                                <input id="gender-male" type="radio" name="gender" value="male" />
                                                <label for="gender-male">Male</label>
                                                <input id="gender-female" type="radio" name="gender" value="female" />
                                                <label for="gender-female">Female</label>
                                                <input id="gender-others" type="radio" name="gender" value="female" />
                                                <label for="gender-others">Others</label>
                                            </div>
                                        </div>
                                    </div>

                               <h4>Address Details</h4>
                               <div className='row'>
                                        <div className="input-box col-xl-6 col-md-6 col-12">
                                            <input type="text" required="true" name="name" placeholder="Country" id="country" />
                                        </div>
                                        <div className="input-box col-xl-6 col-md-6 col-12">
                                            <input type="text" required="true" name="name" placeholder="Pincode/Zipcode" id="pincode" />
                                        </div>


                                    </div>
                                    <div className='row'>
                                        <div className="input-box col-xl-6 col-md-6 col-12">
                                            <input type="text" required="true" name="name" placeholder="State" id="state" />
                                        </div>
                                        <div className="input-box col-xl-6 col-md-6 col-12">
                                            <input type="text" required="true" name="name" placeholder="City" id="city" />
                                        </div>


                                    </div>
                                    <div className="input-box">
                                        <input type="submit" value="Send" name="" />
                                    </div>
                                </form>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
