import React from 'react'
import Header from './Header'
import { Link } from 'react-router-dom'

export default function ServicesYoga() {
    return (
        <>
            <Header />
            <div className="about">
                <div style={{ marginTop: "100px" }} className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 mt-2 heading">
                            <img src="images/leaf.png" alt="" />
                            <h2>Our Services</h2>
                            <h3>Brahma Darshan Yoga</h3>
                        </div>
                    </div>
                    <div className="row blog" data-aos="fade-up" data-aos-duration="400">
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/contactus"><img src="images/worldyogaF1.jpeg" className="zoom" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4><Link to="/contactus">200 hours Teacher Training Course...</Link></h4>
                                            <p>Hosted by <b><Link to="/contactus">Abhay Kumar</Link></b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/contactus"><img src="images/worldyogaF1.jpeg" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4>  <Link to="/contactus">300 hours Teacher Training Course...</Link></h4>
                                            <p>Hosted by <b><Link to="/contactus">Abhay Kumar</Link></b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/contactus"><img src="images/worldyogaF2.jpeg" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4>  <Link to="/contactus">500 hours Teacher Training Course...</Link></h4>
                                            <p>Hosted by <b>  <Link to="/contactus">Abhay Kumar</Link></b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/contactus"><img src="images/worldyogaF2.jpeg" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4>  <Link to="/contactus">800 hours Teacher Training Course...</Link></h4>
                                            <p>Hosted by <b>  <Link to="/contactus">Abhay Kumar</Link></b></p>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/contactus"><img src="images/yogalogo.png" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4>  <Link to="/contactus">Offline 1 on 1 Session...</Link></h4>
                                            <p>Hosted by <b>  <Link to="/contactus">Abhay Kumar</Link></b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/contactus"><img src="images/yogalogo.png" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4>  <Link to="/contactus">Online 1 on 1 Session...</Link></h4>
                                            <p>Hosted by <b>  <Link to="/contactus">Abhay Kumar</Link></b></p>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
