import React from 'react'
import { Link } from 'react-router-dom'

export default function Services() {
    return (
        <>
            <div className="services">
                <div className="container-fluid">
                    <div className="row" data-aos="fade-up" data-aos-duration="400">
                        <div className="col-md-3">
                            <figure>
                                <img src="images/services-bg.jpg" alt="The Pulpit Rock" />
                            </figure>
                            <div className="gradient"></div>
                        </div>
                        <div className="col-md-9 right-part">
                            <div className="row">
                                <div className="col-md-12">
                                    <h2>Teacher Training Course!</h2>
                                    <h3>Preaching Yoga to Professionals</h3>
                                    <p>Are you passionate about sharing the gift of yoga with others?...</p>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-12 contant-part-1">
                                            <ul>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/ttcsyllabus">200 hours Teacher Training Course <h5 style={{color:"#08a454", display:"inline", fontWeight:"bold"}} class="text-red blink-soft">Apply Now</h5></Link> </span>  </li>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/ttcsyllabus">300 hours Teacher Training Course <h5 style={{color:"#08a454", display:"inline", fontWeight:"bold"}} class="text-red blink-soft">Apply Now</h5></Link></span></li>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/ttcsyllabus">500 hours Teacher Training Course <h5 style={{color:"#08a454", display:"inline", fontWeight:"bold"}} class="text-red blink-soft">Apply Now</h5></Link></span></li>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/ttcsyllabus">800 hours Teacher Training Course <h5 style={{color:"#08a454", display:"inline", fontWeight:"bold"}} class="text-red blink-soft">Apply Now</h5></Link></span></li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-6     col-lg-6 col-md-6 col-12 contant-part-2">
                                            <ul>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/serviceyoga">Satkarma</Link></span> </li>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/serviceyoga">Neti, Kunjal</Link></span></li>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/serviceyoga">Panchkarma</Link></span> </li>
                                                <li><i className="fa fa-envira" aria-hidden="true"></i><span><Link to="/serviceyoga">Detoxification</Link></span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
