import React from 'react'
import { Link } from 'react-router-dom'

export default function Testimonials() {
  return (
    <>
      <div className="blog">

        <div className="container">
          <div className="row">
            <div className="col-md-12 col-12 heading">
              <img src="images/leaf.png" alt="" />
              <h2>Testimonails !</h2>
              <h3>Our Happy Clients !</h3>
            </div>
          </div>
          <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
              <div className="carousel-item active">
                <div className="cards-wrapper">
                  <div className="card">
                    <img src="images/blog-2.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Kritsna Gupta</h5>
                      <p className="card-text"> I have joined the teacher training course of 200 hours at brahma-darshan yoga Institute which is very awesome, energetic, interesting. Their teachers are very co-operative especially abhay sir. He taught us so well. I really satisfied from this brahma-darshan yoga Institute. It finally settled my good carrier and placement in yoga</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <img src="images/blog-1.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Richa Agarwal</h5>
                      <p className="card-text">When I met him,I realised he is a very qualified yoga teacher. It was a good decision to have selected him as my trainer. I was facing numerous problems,like lower back pain,migraine, stiffness in my thighs,knee trouble and many more. Under his supervision and encouragement, I feel a marked improvement in my physical as well as mental health.</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <img src="images/blog-3.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Tanya Rai</h5>
                      <p className="card-text">Abhay sir is a very learned professional and he makes sure he goes beyond just teaching yoga. He tells you benefits of each asana, suggests you food you can eat, makes you do kriya such as neti, kunjal etc.
                        I’ve seen drastic changes in my skin and body ever since I’ve been practising yoga with him:</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="cards-wrapper">
                  <div className="card">
                    <img src="images/blog-1.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Nidhi Ramchandani</h5>
                      <p className="card-text">I have been practicing Pranayama with Abhay sir recently. His knowledge and commitment levels are motivating to stay disciplined and get deeper into the practice. I am hoping to excel with his experience 🙏 and stay focused on my wellness journey. …</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <img src="images/blog-3.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Shilpika Jain</h5>
                      <p className="card-text">Abhay Sir is extremely knowledgeable and experienced in yogic practices and sciences. He is a very compassionate and caring teacher who understands their health issues first before taking classes. After starting his class, I have become very active and feel healthy now. All thanks to Abhay Sir</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <img src="images/blog-2.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Sanjay Agarwal</h5>
                      <p className="card-text">I started Yoga with Abhay ji few months ago. Initially I thought of taking his help for 1-2 months only. It has been 4 months now, i would like to continue further. His knowledge about Yoga is very good and he is very professional also. </p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="carousel-item">

                <div className="cards-wrapper">
                  <div className="card">
                    <img src="images/blog-1.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Card title</h5>
                      <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <img src="images/blog-3.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Card title</h5>
                      <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                  <div className="card d-none d-md-block">
                    <img src="images/blog-1.jpg" className="card-img-top" alt="..." />
                    <div className="card-body">
                      <h5 className="card-title">Card title</h5>
                      <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                      <Link to="/contactus" className="btn btn-success">Contact Us</Link>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
              <span className="carousel-control-prev-icon" aria-hidden="true"></span>
              <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
              <span className="carousel-control-next-icon" aria-hidden="true"></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </>
  )
}
