import React from 'react'
import Header from './Header'
import Contact from './Contact'

export default function Trainers() {
    return (
        <>
            <Header />
            <div className="about">

                <div style={{ marginTop: "100px" }} className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 mt-2 heading">
                            <img src="images/leaf.png" alt="" />
                            <h2>Our Trainers</h2>
                            <h3>Brahma Darshan Yoga</h3>
                        </div>
                    </div>
                    {/* <h2 data-aos="fade-up" data-aos-delay="200">Our Services</h2> */}
                    <div className="part-1" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Acharya Abhay (Founder)</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/abhaysir.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p> Acharya Abhay emerges as a beacon of enlightenment and physical well-being. As the founder of Brahma Darshan Yoga, his journey is not just about mastering the art of yoga but also about awakening the inner consciousness of individuals seeking harmony and balance in life.

Born into a family deeply rooted in the ancient traditions of yoga, Acharya Abhay's connection with this ancient discipline began early in his life. His upbringing was steeped in the teachings of renowned yogis and spiritual gurus, laying the foundation for his profound understanding of the holistic practice of yoga.</p>
                            </div>
                        </div>
                    </div>
                    <div className="part-2" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Teacher</h3>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>I am a yoga teacher and have completed the core E-RYT 200 course. The special thing is that I do yoga with pleasure. I have healed ailments like anxiety, heart disease, diabetes and asthma. I am an expert in weight loss.</p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/Teacher1.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="part-3" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Malay Pawan</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/MALAYPAWAN.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>Joined Yogashram Rikhia Dham in 1990 which is situated nearby my residence at Deoghar, Jharkhand.After completion of Graduation in 2001 joined the Yogashram at Rikhiya.
                                    Done PGD in 2002 from “Bihar School of Yoga” (BSY) or Bihar Yoga Bharti (BYB), World's First Yoga University located at Munger,Bihar.
                                    Specialization in Satyananda Yoga Tradition and currently working as Yoga Teacher and Yoga Consultant in Delhi NCR
                                    Currently giving regular yoga sessions to the Judge of Delhi High Court & conducting several types of group & individual classes.</p>
                            </div>

                        </div>
                    </div>
                    <div className="part-4" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Dr.Saumya Yadav Sinha</h3>
                            </div>
                        </div>
                        <div className="row">

                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>Dr. Saumya Yadav Sinha, educator and dietitian
                                    Area of Specialization: Education, Dietetics, Lifestyle Modification,
                                    Dr. Saumya has been a part of the education system for almost two decades and has heartily devoted her time and efforts to generate and incorporate good teaching and learning methodologies. She also possesses enriching experience in the fields of health and nutrition.
                                    As an educationist, she understands the value of quality education, and her prime focus has been to form a strong literary base for the upcoming generations. In addition to this, she also emphasizes the monumental role of sustainable nutrition for everyone, which contributes towards a contented state of mind and body.
                                    She was associated with many renowned schools and hospitals, like DPS, Ryan International, The Millennium School, Apollo Hospitals, and Express Clinic PVT Ltd.
                                    Currently, she is on her journey towards becoming an entrepreneur, taking her knowledge of nutrition to another level. Her line of products consists mainly of health and nutrition-related food products.</p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/SaumyaYadavSinha.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="part-5" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Teacher</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/maam.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>Our dedicated yoga instructor based in the heart of New Jersey. With a passion for both the practice and the profound impact it can have on one's life, She brings a unique blend of expertise, compassion, and dedication to her classes. Her nurturing approach and deep understanding of yoga philosophy create a welcoming space for students of all levels to explore and grow in their practice. Through her guidance, students not only develop strength, flexibility, and balance in their bodies but also find a sense of peace, clarity, and connection within themselves. </p>
                            </div>

                        </div>
                    </div>


                </div>
            </div>
            <Contact/>
        </>
    )
}
