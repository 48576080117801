import React, { useEffect } from 'react';
import AboutUs from './Components/AboutUs';
import Home from './Components/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AOS from 'aos';
import Trainers from './Components/Trainers';
import Contactus from './Components/Contactus';
import ServicesYoga from './Components/ServicesYoga';
import BlogYoga from './Components/BlogYoga';
import Depression from './Components/Blogs/Depression';
import Stress from './Components/Blogs/Stress';
import TeacherTrainingCourse from './Components/Blogs/TeacherTrainingCourse';
import Anxiety from './Components/Blogs/Anxiety';
// import TTC200hrs from './Components/TTC/TTC200hrs';
// import TTC300hrs from './Components/TTC/TTC300hrs';
// import TTC500hrs from './Components/TTC/TTC500hrs';
// import TTC800hrs from './Components/TTC/TTC800hrs';
import TTCcontact from './Components/TTC/TTCcontact';
import TTCSyllabus from './Components/TTC/TTCSyllabus';



function App() {
  useEffect(() => {
    AOS.init();
  }, [])
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/aboutus' element={<AboutUs />} />
          <Route path='/trainers' element={<Trainers />} />
          <Route path='/contactus' element={<Contactus />} />
          <Route path='/serviceyoga' element={<ServicesYoga />} />
          <Route path='/blogyoga' element={<BlogYoga />} />
          <Route path='/depression' element={<Depression />} />
          <Route path='/stress' element={<Stress />} />
          <Route path='/ttc' element={<TeacherTrainingCourse />} />
          <Route path='/anxeity' element={<Anxiety />} />
          {/* <Route path='/ttc200hours' element={<TTC200hrs/>} />
          <Route path='/ttc300hours' element={<TTC300hrs/>} />
          <Route path='/ttc500hours' element={<TTC500hrs/>} />
          <Route path='/ttc800hours' element={<TTC800hrs/>} /> */}
          <Route path='/ttccontact' element={<TTCcontact/>} />
          <Route path='/ttcsyllabus' element={<TTCSyllabus/>} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
