import React from 'react'

export default function Headquaters() {
    return (
        <>
            <div className="blog">

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 heading">
                            <img src="images/leaf.png" alt="" />
                            <h2>Our Headquaters</h2>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up" data-aos-duration="400">
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-card-2"><img src="images/abhaysir.jpeg" data-toggle="modal" data-target=".bd-example-modal-lg" className="img img-responsive" alt='abhaySir' />
                                        <div className="profile-name">Abhay Kumar</div>
                                        <div className="profile-username">Noida, India</div>
                                        {/* <div className="profile-icons"><a href="#"><i className="fa fa-facebook"></i></a><a href="#"><i className="fa fa-twitter"></i></a><a href="#"><i className="fa fa-linkedin"></i></a></div> */}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-12">
                                    <div className="profile-card-2"><img src="images/abhaysir.jpeg" data-toggle="modal" data-target=".bd-example-modal2-lg" className="img img-responsive" alt='abhaySir' />
                                        <div className="profile-name">Abhay Kumar</div>
                                        <div className="profile-username">New Jersey, USA</div>
                                        {/* <div className="profile-icons"><a href="#"><i className="fa fa-facebook"></i></a><a href="#"><i className="fa fa-twitter"></i></a><a href="#"><i className="fa fa-linkedin"></i></a></div> */}
                                    </div>
                                </div>
                            </div>
                                 
            
                           
                               
                            
                            </div>
                 
                    </div>
                    </div>

                    </div>
            <div className="modal fade bd-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className='container' >
                            <div className='row'>
                                <div className='col-md-12 col-12'>
                                    <div className='row'>
                                        <div className='col-md-12 col-4'>
                                            <iframe title='Brahma Darshan Yoga Noida' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3033.039587819385!2d77.36749178534198!3d28.562840622080863!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5f186cda74b%3A0xefb5528d61023bb1!2sa%2C%205%2C%20D%20Block%2C%20Sector%2049%2C%20Noida%2C%20Uttar%20Pradesh%20201301!5e0!3m2!1sen!2sin!4v1714498806287!5m2!1sen!2sin" width="780" height="450" style={{ border: "0", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
                                        </div>
                                        {/* <div className='col-md-4 col-0'>
                                         <div ><img src="images/abhay.png" className="img img-responsive" alt='abhaySir' /></div>
                                         </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade bd-example-modal2-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className='container' >
                            <div className='row'>
                                <div className='col-md-12 col-12'>
                                    <div className='row'>
                                        <div className='col-md-12 col-4'>
                                            <iframe title='Brahma Darshan Yoga Noida' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3069.5286563323107!2d-75.11625592359916!3d39.70530049863145!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6d7aea78a30b5%3A0x746ab52356be18bd!2s220%20Rowan%20Boulevard%2C%20220%20Rowan%20Blvd%20%23522%2C%20Glassboro%2C%20NJ%2008028%2C%20USA!5e0!3m2!1sen!2sin!4v1710606477128!5m2!1sen!2sin" width="780" height="450" style={{ border: "0", allowfullscreen: "", loading: "lazy", referrerpolicy: "no-referrer-when-downgrade" }}></iframe>
                                        </div>
                                        {/* <div className='col-md-4 col-0'>
                                         <div ><img src="images/abhay.png" className="img img-responsive" alt='abhaySir' /></div>
                                         </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>





            
        
        </>
    )
}
