import React from 'react'
import About from './About';
import Blog from './Blog';
import Contact from './Contact';
import ControlBody from './ControlBody';
import Header from './Header';
import Headquaters from './Headquaters';
import Navbar from './Navbar';
import Services from './Services';
import Testimonials from './Testimonials';

export default function Home() {
  return (
    <>  
   

    <Header/>
    <Navbar/>
    <About/>
    <Services/>
    <ControlBody/>
    <Blog/>
    <Headquaters/>
    <Testimonials/>
    <Contact/>
    </>
  )
}