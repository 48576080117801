import React from 'react'
import Header from './Header'
import { Link } from 'react-router-dom'
import Contact from './Contact'

export default function BlogYoga() {
    return (
        <>
        <Header/>

            <div className="blog">

                <div className="container mt-100">
                    <div className="row">
                        <div className="col-md-12 col-12 heading">
                            <img src="images/leaf.png" alt="" />
                            <h2>Some useful Yoga Articles for you!</h2>
                            <h3>Good for all men, women and childrens</h3>
                        </div>
                    </div>
                    <div className="row" data-aos="fade-up" data-aos-duration="400">
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/depression"><img src="images/depression.jpg" className="zoom" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4><Link to="/depression">Healing Depression with Yoga: A Journey to Inner Peace at Brahma Darshan Yoga</Link></h4>
                                            <p>Posted by <b><Link to="/depression">Abhay Kumar</Link></b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/stress"><img src="images/stress.jpg" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4><Link to="/stress">Finding Balance: How Yoga at Brahma Darshan Yoga Can Help You Manage Stress</Link></h4>
                                            <p>Posted by <b><Link to="/stress">Abhay Kumar</Link></b></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 col-12">
                            <div className="row">
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/ttc"><img src="images/worldyogaF2.jpeg" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4><Link to="/ttc">Ignite Your Passion: Become a Certified Yoga Teacher with Brahma Darshan Yoga</Link></h4>
                                            <p>Posted by <b><Link to="/ttc">Abhay Kumar</Link></b></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-12 col-12">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <figure>
                                                <Link to="/anxeity"><img src="images/anxeity.jpg" alt="The Pulpit Rock" width="304" height="228" /></Link>
                                            </figure>
                                        </div>
                                        <div className="col-md-8 inner-content">
                                            <h4><Link to="/anxeity">Finding Calm in the Storm: Managing Anxiety with Yoga at Brahma Darshan Yoga</Link></h4>
                                            <p>Posted by <b><Link to="/anxeity">Abhay Kumar</Link></b></p>
                                            <p></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 col-12 button">
                            <Link className="btn btn-success" to="/blogyoga" role="button">Load more</Link>
                        </div>
                    </div>
                </div>

            </div>
            <Contact/>
        </>
    )
}
