import React from 'react'
import {Link} from 'react-router-dom'
export default function About() {
  return (
   <>
       <section className="about">
        
        <div className="container">
            <div className="row">
               <div className="col-md-12 col-12 heading">
                    <img src="images/leaf.png" alt=""/>
                    <h2>Yoga as a lifestyle, Body & Soul</h2>
                    <h3>interesting blog posts on creativity and design</h3>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 col-sm-12 col-12 box-1" data-aos="fade-right" data-aos-duration="400">
                    <div className="circle">
                        <div className="gradient"></div>
                        <div className="circle__inner">
                            <div className="circle__wrapper">
                                <div className="circle__content">
                                    <h4><Link to="/serviceyoga">Yoga <span>for</span> Depression</Link></h4>
                                    {/* <p><Link to="/serviceyoga">Lorem Ipsum is simply dummy text</Link></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-12 box-2" data-aos="fade-up" data-aos-duration="400">
                    <div className="circle">
                        <div className="gradient"></div>
                        <div className="circle__inner">
                            <div className="circle__wrapper">
                                <div className="circle__content">
                                    <h4><Link to="/serviceyoga">Teacher Training Course</Link></h4>
                                    {/* <p><Link to="/serviceyoga">Lorem Ipsum is simply dummy text </Link></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4 col-sm-12 col-12 box-3" data-aos="fade-left" data-aos-duration="400">
                    <div className="circle">
                        <div className="gradient"></div>
                        <div className="circle__inner">
                            <div className="circle__wrapper">
                                <div className="circle__content">
                                    <h4><Link to="/serviceyoga">Yoga <span>for</span>Therapy</Link></h4>
                                    {/* <p><Link to="/serviceyoga">Lorem Ipsum is simply dummy text </Link></p> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  
    </section>
   </>
  )
}
