import React from 'react'
import Header from '../Header'
import { Link } from 'react-router-dom'
export default function TTCSyllabus() {
    return (
        <>
            <Header />
            <div className="about">
                <div style={{ marginTop: "100px" }} className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 mt-2 heading">
                            <img src="images/leaf.png" alt="" />
                            <h2>Teacher Training Course</h2>
                            <h3>Syllabus</h3>
                            <Link to={'/ttccontact'}><h6 style={{ color: "#08a454", display: "inline", fontWeight: "bold" }} class="text-red blink-soft">Apply Now</h6></Link>
                        </div>
                    </div>
                    <div className="part-1" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Yoga Philosophy and Psychology:- (Theory)</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>⦁	Introduction/ Concept / Meaning / Definition of Yoga.</p>
                                <p>⦁	Introduction of Bhagvat Gita</p>
                                <p>⦁	History and Origin of Yoga.</p>
                                <p>⦁	Concept of Ashtang Yog.</p>
                                <p>⦁	Science of Chakras, Kundalini and Nadis.</p>
                                <p>⦁	Panchkoshas:- The five bodies</p>
                                <p>⦁	Mantrayoga</p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog1.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>
                    <div className="part-2" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Hatha Yoga: - (Theory) Yoga Introduction/ Yoga Philosophy/ Parts of Yoga/ Branches of Yoga.
                                </h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>⦁	Pawan Muktasan:- Part 1, 2, 3</p>
                                <p>⦁	Meditation Asanas:- Sukhasana, Vajrasana, Padmasana</p>
                                <p>⦁	Vajrasano Group of Asanos. - Vajrasana, Padadhirasana, Simhasana, Marjhari Asana, Vyaghrasana, Shashankasana, Shashank Bhujangasana, Ushtrasana, Supta Vajrasana.</p>
                                <p>⦁	Yoga Exercises for Eyes.</p>
                                <p>⦁	Relaxation Asanas. - Shavasana, Advasana, Makarasana etc.</p>
                                <p>⦁	Standing Asanos.- Hasta Utthanasana, Tadasana, Tiryak Tadasana, Katichakrasana, Utthanasana, Druta Utkatasana, Samakonasana, Dwikonasana, Trikonasana.</p>

                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog2.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>
                    <div className="part-3" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                {/* <h3><i className="fa fa-envira" aria-hidden="true"></i>Yoga Philosophy and Psychology:- (Theory)</h3> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>⦁	Intermediate Group of Asanas.- Yogamudrasana, Matsyasana, Baddha Padmasana, Lolasana, Bhujangasana, Sarpasana, Ardha Shalabhasan, Shalabhasana, Saral Dhanurasana, Dhanurasna, Kandhrasana, Setu Asana etc.</p>
                                <p>⦁	Forward Bending Asanas:- Janu Shirshasana, Paschimottanasana, Hasta Pada Angushthasana, Padahastasana</p>
                                <p>⦁	Spinal Twisting Asanas.’- Meruwakrasana, Ardha Matsyendrasana, Bhu Namanasana.</p>
                                <p>⦁    Inverted Asanas.- Vipreeta Karani Asana, Sarvangasana, Halasana, Shirshasana.</p>
                                <p>⦁    Balancing Asanas.- Eka Pada Pranamasana, Natarajasana, Garudasana, Eka Padasana, Vashishtasana.</p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog3.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>
                    <div className="part-4" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Pranayama: - (Theory) Meaning of Pranayanma/ Pranic Body/ Types of Pranayamas.</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>⦁	Abdominal Breathing.</p>
                                <p>⦁	Thoracic Breathing.</p>
                                <p>⦁	Yogic Breathing.</p>
                                <p>⦁	Nadi Shodhan:- Psychic Network Purification.</p>
                                <p>⦁	Sheethali- Cooling Breath.</p>
                                <p>⦁	L/jjay/- The Psychic Breath</p>
                                <p>⦁	Bhramari- Humming Bee Breath</p>
                                <p>⦁	Bhastrika’- The Bellow Breath</p>
                                <p>⦁	Kapalbhati- Frontal Brain Cleaning Breath</p>
                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog4.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>
                    <div className="part-5" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                {/* <h3><i className="fa fa-envira" aria-hidden="true"></i>Pranayama: - (Theory) Meaning of Pranayanma/ Pranic Body/ Types of Pranayamas.</h3> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <h6>Mudras:-</h6>
                                <p>⦁ Jnana Mudra</p>
                                <p>⦁ Chin Mudra</p>
                                <p>⦁ Shambhavi Mudra</p>
                                <p>⦁ Khechari Mudra</p>

                                <h6>Yoga Mudra:-</h6>
                                <p>⦁ Ashwini Mudra</p>
                                <p>⦁ Vajroli / Sahajoli Mudra</p>

                                <h6>Bandha:-</h6>
                                <p>⦁ Jalandhar Bandha</p>
                                <p>⦁ Moola Bandha</p>
                                <p>⦁ Uddiyan Bandha</p>

                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog5.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>
                    <div className="part-6" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                {/* <h3><i className="fa fa-envira" aria-hidden="true"></i>Pranayama: - (Theory) Meaning of Pranayanma/ Pranic Body/ Types of Pranayamas.</h3> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <h6>Shathkarma:-</h6>
                                <p>⦁ la/a Neti.’- Water Nasal Cleansing</p>
                                <p>⦁ Laghoo Shankha Prakshalan.’- Cleansing of the Intestine</p>
                                <p>⦁ Agnisar Kri/a.- Activating the Digestive Fire</p>
                                <p>⦁ Kunjal Kriya.- Cleansing process of Throat and Mucus</p>

                                <p>⦁ Kapalbhati</p>
                                <p>⦁ Trataka</p>

                                <h6>Human Anatomy and Physiology:-</h6>
                                <p>⦁ Skeleton S stem. Respiratory system</p>
                                <p>⦁ Digestive System.- Circulatory System</p>
                                <p>4. Muscular System</p>

                                <h6> Meditation Practice:-</h6>

                                <p>⦁ A tarN dna dinner Silence</p>

                                <p>⦁ Nervous System.- Endocrinal system</p>
                                <p>⦁ MusculsiF System</p>

                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog6.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>
                    <div className="part-7" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                {/* <h3><i className="fa fa-envira" aria-hidden="true"></i>Pranayama: - (Theory) Meaning of Pranayanma/ Pranic Body/ Types of Pranayamas.</h3> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <h6> Meditation Practice:-</h6>
                                <p>⦁ Yoga Nindra</p>
                                <p>⦁ Antarmauna (Inner Silence)</p>
                                <p>⦁ Ajapa/ Japa/ Mantra Meditation</p>
                                <p>⦁ Chakra Meditation</p>
                                <h6> Yoga Therapy for Common Diseases:-</h6>
                                <p>⦁ Migraine / Head Aches</p>
                                <p>⦁ HBP / LBP</p>
                                <p>⦁ Hormonal Issues:-Hormonal Imbalance /Thyroid/ Diabetes</p>


                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog8.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>
                    <div className="part-8" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                {/* <h3><i className="fa fa-envira" aria-hidden="true"></i>Pranayama: - (Theory) Meaning of Pranayanma/ Pranic Body/ Types of Pranayamas.</h3> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8 col-sm-6 col-12 col1">

                                <p>⦁ Asthma/ Sinusitis/ Cold</p>
                                <p>⦁ Constipation</p>
                                <p>⦁ Obesity</p>
                                <p>⦁ Anxiety/Depression/ Insomnia/ Stress and Mind Management</p>
                                <p>⦁ Liver Problems</p>
                                <p>⦁ Slip Disk / Back Pain/ Sciatica/Cervical Spondylitis</p>
                                <p>⦁ Skin Problems</p>
                                <p>⦁ Cancer</p>
                                <p>⦁ Eye Problems</p>
                                <p>Naturopathy:- (Theory) Introduction/ Magnetic Treatment/</p>
                                <p>⦁ Accupressure/Ayurveda</p>

                            </div>
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/yog2.jpg" alt="Web Design" width="100%" />
                                </figure>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
