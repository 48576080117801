import React from 'react'
import Header from './Header'
import Contact from './Contact'

export default function AboutUs() {
    return (
        <>
            <Header />
            {/* <div id="services">
        <div className="container">
            <div className="row">
                <div className="col-md-12 col-12 about heading">
                <img src="images/leaf.png" alt=""/>
            <h2 data-aos="fade-up" data-aos-delay="200">Our Services</h2>
            <div className="part-1" data-aos="fade-up" data-aos-delay="400">
                <div className="row">
                    <div className="col-md-12 col-12 heading">
                 
                        <h3><i className="fa fa-envira" aria-hidden="true"></i>Yoga for Old</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6 col-12">
                        <figure>
                            <img src="images/Web-Design.jpg" alt="Web Design" width="100%"/>
                        </figure>
                    </div>
                    <div className="col-md-8 col-sm-6 col-12 col1">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                </div>
            </div>
            <div className="part-2" data-aos="fade-up" data-aos-delay="400">
                <div className="row">
                    <div className="col-md-12 heading">
                        <h3><i className="fa fa-envira" aria-hidden="true"></i>Spring Yoga Sessions</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <figure>
                            <img src="images/psd-to-html.jpg" alt="Web Design" width="100%"/>
                        </figure>
                    </div>
                    <div className="col-md-8 col-sm-6 col1">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                </div>
            </div>
            <div className="part-2" data-aos="fade-up" data-aos-delay="400">
                <div className="row">
                    <div className="col-md-12 heading">
                        <h3><i className="fa fa-envira" aria-hidden="true"></i>Yoga for Old</h3>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <figure>
                            <img src="images/Web-Design.jpg" alt="Web Design" width="100%"/>
                        </figure>
                    </div>
                    <div className="col-md-8 col-sm-6 col1">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                </div>
            </div>
            </div>
            </div>
        </div>
    </div> */}
            <div className="about">

                <div style={{ marginTop: "100px" }} className="container">
                    <div className="row">
                        <div className="col-md-12 col-12 mt-2 heading">
                            <img src="images/leaf.png" alt="" />
                            <h2>AboutUs !</h2>
                            <h3>Brahma Darshan Yoga</h3>
                        </div>
                    </div>
                    {/* <h2 data-aos="fade-up" data-aos-delay="200">Our Services</h2> */}
                    <div className="part-1" data-aos="fade-up" data-aos-delay="400">
                        <div className="row">
                            <div className="col-md-12 col-12  heading">
                                <h3><i className="fa fa-envira" aria-hidden="true"></i>Abhay Sir</h3>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-6 col-12">
                                <figure>
                                    <img src="images/abhaysir.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                            <div className="col-md-8 col-sm-6 col-12 col1">
                                <p>Welcome to Brahma Darshan Yoga, Embark on a transformative journey of self-discovery and holistic well-being with Brahma Darshan Yoga. Our renowned yoga school offers a sanctuary for those seeking to deepen their practice, cultivate mindfulness, and embark on the path to becoming certified yoga teachers.At Brahma Darshan Yoga, we believe in the power of community and connection. Our vibrant and inclusive community welcomes students of all backgrounds and experience levels. Whether you're a seasoned yogi or stepping onto the mat for the first time, you'll find a supportive network of like-minded individuals on your path to self-discovery and empowerment.

                                    Experience the Transformative Power of Yoga

                                    Discover the profound benefits of yoga for mind, body, and spirit at Brahma Darshan Yoga. Explore our teacher training courses, workshops, and retreats to deepen your practice, expand your knowledge, and awaken your highest potential. Join us on this journey of self-exploration, healing, and transformation.

                                    Contact Brahma Darshan Yoga today to start your yoga journey!</p>
                            </div>
                        </div>
                    </div>
                    <div className="part-2" data-aos="fade-up" data-aos-delay="400">
                        {/* <div className="row">
                    <div className="col-md-12 heading">
                        <h3><i className="fa fa-envira" aria-hidden="true"></i>Ma'am</h3>
                    </div>
                </div> */}
                        <div className="row">

                            {/* <div className="col-md-8 col-sm-6 col1">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div> */}
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <figure>
                                    <img src="images/abhaygroup.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="part-3" data-aos="fade-up" data-aos-delay="400">
                        {/* <div className="row">
                    <div className="col-md-12 heading">
                        <h3><i className="fa fa-envira" aria-hidden="true"></i>Yoga for Old</h3>
                    </div>
                </div> */}
                        <div className="row">

                            <div className="col-md-8 col-sm-6 col1">
                                <p>Yoga for Mental Health

                                    At Brahma Darshan Yoga, we understand the profound impact that yoga can have on mental health. In today's fast-paced world, where stress, depression, and anxiety are prevalent, yoga serves as a powerful tool for healing and self-care. Through our carefully crafted yoga practices, mindfulness techniques, and meditation sessions, we empower individuals to find inner peace, resilience, and balance amidst life's challenges.</p>
                            </div>
                            <div className="col-md-4 col-sm-6">
                                <figure>
                                    <img src="images/abhaysir.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="part-4" data-aos="fade-up" data-aos-delay="400">
                        {/* <div className="row">
                    <div className="col-md-12 heading">
                        <h3><i className="fa fa-envira" aria-hidden="true"></i>Ma'am</h3>
                    </div>
                </div> */}
                        <div className="row">

                            {/* <div className="col-md-8 col-sm-6 col1">
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div> */}
                            <div className="col-md-12 col-sm-12 col-lg-12">
                                <figure>
                                    <img src="images/abhaymic.jpeg" alt="Web Design" width="100%" />
                                </figure>
                            </div>
                        </div>
                    </div>
                    <div className="part-5" data-aos="fade-up" data-aos-delay="400">
                        {/* <div className="row">
                    <div className="col-md-12 heading">
                        <h3><i className="fa fa-envira" aria-hidden="true"></i>Ma'am</h3>
                    </div>
                </div> */}
                        <div className="row">

                            <div className="col-md-12 col-sm-12 col1">
                                <p>Teacher Training Courses

                                    Are you passionate about sharing the gift of yoga with others? Our comprehensive teacher training courses provide aspiring instructors with the knowledge, skills, and confidence to guide others on their yoga journey. Led by experienced and dedicated teachers, our courses cover a wide range of topics including asana, pranayama, anatomy, philosophy, and teaching methodology. Whether you're looking to deepen your personal practice or pursue a fulfilling career in yoga instruction, our teacher training program offers a supportive and enriching learning experience.

                                    About Brahma Darshan Yoga

                                    Founded with a vision to spread the wisdom of yoga far and wide, Brahma Darshan Yoga is rooted in the ancient teachings of yoga philosophy. Our name, "Brahma Darshan," signifies the divine vision and insight that yoga can awaken within each of us. Drawing inspiration from this profound wisdom, we strive to create a nurturing environment where students can explore, learn, and grow on their yoga journey.

                                </p>
                            </div>
                            {/* <div className="col-md-12 col-sm-12 col-lg-12">
                        <figure>
                            <img src="images/abhaygroup.jpeg" alt="Web Design" width="100%"/>
                        </figure>
                    </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Contact/>
        </>
    )
}
