import React from 'react'
import { Link } from 'react-router-dom'

export default function ControlBody() {
    return (
        <>
            <div className="section-4">
                <div className="container-fluid">
                    <div className="row" data-aos="fade-up" data-aos-duration="400">
                        <div className="col-md-9 right-part">
                            <div className="row">
                                <div className="col-md-12 heading">
                                    <h2>Control, Body & Mind!</h2>
                                    <h3>Good for all men, women and childrens</h3>
                                </div>
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-3 col-6">
                                            <figure>
                                                <img src="images/services-bg.jpg" alt="The Pulpit Rock" />
                                            </figure>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <figure>
                                                <img src="images/blog-1.jpg" alt="The Pulpit Rock" />
                                            </figure>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <figure>
                                                <img src="images/blog-2.jpg" alt="The Pulpit Rock" />
                                            </figure>
                                        </div>
                                        <div className="col-md-3 col-6">
                                            <figure>
                                                <img src="images/blog-3.jpg" alt="The Pulpit Rock" />
                                            </figure>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <p>At Brahma Darshan Yoga, we believe in the power of community and connection. Our vibrant and inclusive community welcomes students of all backgrounds and experience levels. Whether you're a seasoned yogi or stepping onto the mat for the first time, you'll find a supportive network of like-minded individuals on your path to self-discovery and empowerment....</p>
                                   
                                    <Link to="/contactus" className="btn btn-secondary btn-lg active" role="button" aria-pressed="true">book now</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <figure>
                                <img src="images/section-4-bg.jpg" alt="The Pulpit Rock" />
                            </figure>
                            <div className="gradient"></div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}
