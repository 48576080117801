import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
  return (
   <>
     <div className="banner-home">
     
        <div className="gradient"></div>
       
        <div className="container">
       
            <div className="row">
                <div className="col-sm-12">
                    <h1 data-aos="fade-left">Develop Body, Mind and Spirit!</h1>
                    <h2 data-aos="fade-left" data-aos-delay="100">With Yoga, the Ultimate Transformation....</h2>
                    <p data-aos="fade-left" data-aos-delay="200"><i className="fa fa-clock-o" aria-hidden="true"></i><span>We Are open 24x7</span></p>
                    <p data-aos="fade-left" data-aos-delay="300"><i className="fa fa-phone-square" aria-hidden="true"></i><span>Call for Special Discounts</span></p>
                    <p data-aos="fade-left" data-aos-delay="400"><i className="fa fa-envelope" aria-hidden="true"></i><span>info@brahmadarshanyoga.com</span></p>
                    <Link data-aos="fade-left" data-aos-delay="500" className="btn btn-success" to="/aboutus" role="button">our story</Link>
                </div>
            </div>
      
        </div>
      
    </div>
   </>
  )
}
