import React from 'react'
import Header from './Header'

import { useForm } from '@formspree/react';


export default function Contactus(e) {
  const [state, handleSubmit] = useForm("moqgwybp");

  if (state.succeeded) {

    return <Contactus name={"You will be notified soon!"} />

  }

  return (
    <>
      <Header />
      <div className="about">

        <div style={{ marginTop: "100px" }} classNameName="container">
          <div className="row">
            <div className="col-md-12 col-12 mt-2 heading">
              <img src="images/leaf.png" alt="" />
              <h2>Contact Us</h2>
              <h3>Brahma Darshan Yoga</h3>
            </div>
          </div>



          <div className="container-fluid">
            <div className="row">

              <div className="contact-form">
                <form action="" id="contact-form" onSubmit={handleSubmit}>
             
                    <h1>{e.name}</h1>
                        
                
                  <div className="input-box">
                    <input type="text" required="true" name="name" placeholder="Full Name" id="name" />
                  </div>
                  <div className="input-box">
                    <input type="email" required="true" name="email" placeholder="Email id" id="email" />
                  </div>
                  <div className="input-box">
                    <input type="number" required="true" name="ph" placeholder="Phone Number" id="ph" />
                  </div>
                  <div className="input-box">
                    <textarea required="true" name="message" placeholder="Your Message" id="message"></textarea>
                  </div>
                  <div className="input-box">
                    <input disabled={state.submitting} type="submit" value="Send" name="" />
                  </div>
                </form>
              </div>

            </div>
          </div>

          {/* <div classNameName="contact">

<div classNameName="gradient"></div>

<div classNameName="container">
    <div classNameName="row" data-aos="fade-up" data-aos-duration="400">
        <div classNameName="col-lg-6 col-md-12 col-12 columns-1">
            <h2>Our Address</h2>
            <address>
                <p>B-224, Block E, Sector 46, </p>
                <p> Noida, Uttar Pradesh 201303</p>
                <p>Opening Hours : Mo-Fr 11:00-00:00, Sa-Su 15:00-00:00</p>
                <p>Call for Bookings : <span>+918285038252</span></p>
            </address>
        </div>
        <div classNameName="col-lg-1 col-md-12 col-12"></div>
        <div classNameName="col-lg-12 col-md-12 col-12 ">
            
            <form classNameName="row form-inline">
                <div classNameName="col-md-6 form-group">
                    <input type="text" classNameName="form-control" placeholder="Your Name" required />
                </div>
                <div classNameName="col-md-6 form-group">
                    <input type="email" classNameName="form-control" placeholder="Your Email" required />
                </div>
                <div classNameName="col-md-12 form-group">
                    <textarea classNameName="form-control" placeholder="Message" rows="5" cols="70"></textarea>
                </div>
                <div classNameName="col-md-12">
                    <button type="submit" classNameName="btn btn-primary">Submit</button>
                </div>
            </form>
        </div>
    </div>
</div>

<div classNameName="copyright">
    <div classNameName="container">
        <div classNameName="row border-img">
            <div classNameName="col-md-12">
                <img src="images/border.png" alt="" />
            </div>
        </div>
    </div>
    <div classNameName="container">
        <div classNameName="row" data-aos="fade-up" data-aos-duration="400">
            <div classNameName="col-lg-3 col-md-12">
                <a href="index.html"><img src="img/logo2.png" alt="logo" /></a>
            </div>
            <div classNameName="col-lg-9 col-md-12 right-part">
                <ul classNameName="ml-auto">
                    <li classNameName="nav-item active">
                        <a classNameName="nav-link active" href="index.html">Home <span classNameName="sr-only">(current)</span></a>
                    </li>
                    <li><a classNameName="hidden-xs">~</a></li>
                    <li classNameName="nav-item"><a classNameName="nav-link" href="services.html">Our Services</a></li>
                    <li><a classNameName="hidden-xs">~</a></li>
                    <li classNameName="nav-item"><a classNameName="nav-link" href="products.html">Our Products</a></li>
                    <li><a classNameName="hidden-xs">~</a></li>
                    <li classNameName="nav-item"><a classNameName="nav-link" href="rates.html">Rates</a></li>
                    <li><a classNameName="hidden-xs">~</a></li>
                    <li classNameName="nav-item"><a classNameName="nav-link" href="blog-page.html">Read Blog</a></li>
                </ul>
            </div>
        </div>
    </div>
</div>
</div> */}
        </div>
      </div>
    </>
  )
}
