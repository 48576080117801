import React from 'react'
import { Link } from 'react-router-dom'

export default function Header() {

	
	return (
		<>
			<div className='fixed-bottom p-3' style={{ textAlign: 'end', width: '95%' }}>
				<a href="https://wa.me/918285038252?text=Hello, Acharya Abhay" target="_blank">
					<img src='./images/wp.png' width="60" alt='wp' />
				</a>
			</div>
			{/* <div className="top">
                <div id="mySidenav" className="sidenav">
                    <Link href="www.google.com" className="closebtn" >&times;</Link>
                    <Link className="active" href="index.html">Home</Link>
                    <Link href="about.html">About</Link>
                    <Link href="services.html">Services</Link>
                    <Link href="products.html">Products</Link>
                    <Link href="rates.html">Rates</Link>
                    <Link href="blog-page.html">Blog</Link>
                </div>

                <nav id="navbar">

                    <div className="container">

                        <div className="row">
                            <div className="col-lg-5 col-md-5 align-self-center left-side">
                                <p>Do Yoga, Live Young <span>call 800 1234 56789</span></p>
                            </div>
                            <div className="col-lg-2 col-md-2 col-5 align-self-center logo">
                                <Link href="index.html"><img src="images/nav-logo.png" alt="logo" /></Link>
                            </div>
                            <div className="col-lg-5 col-md-5 col-7 align-self-center right-side">
                                <div className="social-icons square">

                                    <div id="page-content-wrapper">
                                        <span className="slide-menu" ><i className="fa fa-bars" aria-hidden="true"></i></span>
                                    </div>
                                </div>
                                <div className="social-icons another">
                                    <i className="fa fa-facebook-official" aria-hidden="true"></i>
                                    <i className="fa fa-twitter" aria-hidden="true"></i>
                                    <i className="fa fa-pinterest" aria-hidden="true"></i>
                                    <i className="fa fa-youtube" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>

                    </div>

                </nav>

                <img className="border-img" src="images/border.png" width="100%" alt="" />
            </div> */}

			<div id="header">
				<div className="header-top">
					<div className="container">
						<div className="row">
							<div className="col-lg-6 col-sm-6 col-8 header-top-left no-padding">
								{/* <ul>
									<li><Link href="#"><i className="fa fa-facebook"></i></Link></li>
									<li><Link href="#"><i className="fa fa-twitter"></i></Link></li>
									<li><Link href="#"><i className="fa fa-dribbble"></i></Link></li>
									<li><Link href="#"><i className="fa fa-behance"></i></Link></li>
								</ul> */}
							</div>
							<div className="col-lg-6 col-sm-6 col-4 header-top-right no-padding">
								<a href="tel:+918285038252">
									<span className="text" >+918285038252</span>
								</a>
								<Link className="book-now" to="/contactus">Book Now</Link>
							</div>
						</div>
					</div>
				</div>
				<div className="container main-menu">
					<div className="row align-items-center justify-content-between d-flex">
						<div id="logo" className='d-flex'>
							<Link to="/"><img src="img/logo.png" alt="" title="" /></Link>
							{/* <h3 width={'50%'} font-Size={'2px'}>Brahma Darshan Yoga</h3> */}
							<nav className='burgermenu' role="navigation">
							<div id="menuToggle">

								<input type="checkbox" />


								<span></span>
								<span></span>
								<span></span>


								<ul id="menu">
								<li><Link className="active" to="/">Home</Link></li>
									<li><Link to="/aboutus">About</Link></li>
									<li><Link to="/trainers">Trainers</Link></li>
									<li><Link to="/serviceyoga">Services</Link></li>
									<li><Link to="/blogyoga">Blogs</Link></li>
									<li><Link to="/contactus">Contact Us</Link></li>
								</ul>
							</div>
						</nav>
						</div>
					
						
							
							<nav id="nav-menu-container">
								<ul className="nav-menu">
									<li><Link className="active" to="/">Home</Link></li>
									<li><Link to="/aboutus">About</Link></li>
									<li><Link to="/trainers">Trainers</Link></li>
									<li><Link to="/serviceyoga">Services</Link></li>
									<li><Link to="/blogyoga">Blogs</Link>
										{/* <ul>
										<li><Link to="/depression">Yoga for Depression</Link></li>
										<li><Link to="blog-single.html">Blog Single</Link></li>
									</ul> */}
									</li>
									{/* <li className="menu-has-children"><Link to="">Pages</Link>
							<ul>
								<li><Link to="schedule.html">Schedule</Link></li>
								<li><Link to="courses.html">Courses</Link></li>
								<li><Link to="elements.html">Elements</Link></li>
							</ul>
						</li> */}
									<li><Link to="/contactus">Contact Us</Link></li>
								</ul>
							</nav>
					</div>
				</div>
			</div>
		</>
	)
}
